@import url('https://rsms.me/inter/inter.css');

html { font-family: 'Inter', sans-serif; }

@supports (font-variation-settings: normal) {
  html { font-family: 'Inter var', sans-serif; }
}

@tailwind base;
@tailwind components;
@tailwind utilities;